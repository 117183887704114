import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom"

import ConfirmEmail from './pages/ConfirmEmail/ConfirmEmail'


function App() {
  return (
    <Router>
      <Routes>
        <Route path='/auth/confirm-email' element={<ConfirmEmail />} />
      </Routes>
    </Router>
  )
}

export default App;
