import { Button } from 'components'
import React, { useEffect, useRef, useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import mailPending from './mail.png'
import resendIcon from './resend.png'

interface Props {
  
}

const ConfirmEmail = (props: Props): JSX.Element | null => {
  const [ resendTimeout, setResendTimeout ] = useState(60)
  const resendTimeoutRef = useRef<number | null>()
  useEffect(() => {
    resendTimeoutRef.current = timeoutResend(setResendTimeout)

    return () => {
      if (resendTimeoutRef.current !== null) {
        window.clearInterval(resendTimeoutRef.current)
      }
    }
  }, [])

  const resendConfirmation = useCallback(() => {
    if (resendTimeoutRef.current !== null) {
      window.clearInterval(resendTimeoutRef.current)
    }
    resendTimeoutRef.current = timeoutResend(setResendTimeout)
  }, [])

  const [ params ] = useSearchParams()
  const email = params.get('email')
  const username = params.get('username')

  return (
    <Wrapper>
      <Modal>
        <Illustration src={mailPending} />

        <H1>E-Mail-Adresse bestätigen</H1>

        <Body>
          Wir haben Ihnen eine E-Mail an {email ? <Email>{email}</Email> : (
            <>
              die mit dem Benutzer <Email>{username}</Email> verknüpfte Adresse
            </>
          )} geschickt. Bitte öffnen Sie diese und drücken auf "E-Mail bestätigen", um die Accounterstellung abzuschließen.

          <br />
          <br />

          Keine E-Mail erhalten? Fordern Sie einfach eine neue an.
        </Body>

        <ResendButton disabled={resendTimeout > 0} onClick={resendConfirmation}>
          <ResendIcon src={resendIcon} />

          Erneut senden {resendTimeout > 0 && `(${resendTimeout} s)`}
        </ResendButton>
      </Modal>
    </Wrapper>
  )
}

const resendTimeoutSeconds = 60
const timeoutResend = (setTimeout: React.Dispatch<React.SetStateAction<number>>) => {
  setTimeout(resendTimeoutSeconds)

  const inv = window.setInterval(() => {
    setTimeout(t => {
      if (t === 1) {
        window.clearInterval(inv)
      }

      return --t
    })
  }, 1000)

  return inv
}

export default ConfirmEmail

const Wrapper = styled.div`
  background: linear-gradient(135deg, #5678D6, #926BD6BB);

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`

const Modal = styled.div`
  border-radius: 20px;
  box-shadow: 25px 50px 100px rgba(0, 0, 0, 0.25);

  padding: 48px 64px;
  background: #FFF;

  max-width: 715px;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const Illustration = styled.img`
  width: 74px;
  height: 66px;
  margin-left: 8px;
`

const H1 = styled.h1`
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.015em;
  color: #333;
  opacity: 0.9;
`

const Body = styled.p`
  font-size: 20px;
  line-height: 23px;
  color: #333;

  white-space: pre-line;
  margin-bottom: 76px;
`

const Email = styled.span`
  text-decoration: none;
  color: #333;
  font-weight: 500;
`

const ResendButton = styled(Button)`
  width: 100%;

  font-weight: 500;
  font-size: 20px;
  line-height: 23px;

  height: 50px;
`

const ResendIcon = styled.img`
  margin-right: 4px;
  margin-top: 4px;

  opacity: 0.99;
  width: 20px;
  height: 20px;
`
