import styled from 'styled-components'

export const Button = styled.button`
  display: block;
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  background: #3E67D6;
  color: #ffffff;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, opacity 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover &:not(:disabled) {
    background: #4D7DFF;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`
